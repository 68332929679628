<template lang="pug">
    v-form.container.m-auto.mt-6(ref="form" v-model="isValid")
        h1(class="mt-2 mb-8 text-3xl text-center") Change Plan
        SubscriptionOptions(
            :previous-line-items="previousLineItems"
            :subscription-id="subscription.subscriptionId"
        )
        .h-0(v-if="previousLineItemsLoaded")
            StripeCheckoutWrapper(
                :submit-subscription="submitToStripe"
                :previous-line-items="previousLineItems"
                :signup-session-id="signupSession.signupSessionId"
                :subscription-id="subscription.subscriptionId"
                :upgrade="true"
                @finished-upgrade="finishUpgrade"
            )
        .text-center(v-if="showMessage")
            | {{ finishUpgradeMessage }}
        .text-center
            v-btn(
                type="button"
                color="success"
                depressed
                class="w-200 mt-8 mb-6"
                :class="submitToStripe ? 'pointer-events-none' : ''"
                @click="formSubmitHandler"
            )
                fa-icon(v-if="submitToStripe" icon="spinner" spin class="mr-2")
                | {{ changePlanBtnText }}
        .text-center
            v-btn(
                text
                color="grey"
                class="w-200 mt-2 mb-8"
                :class="submitToStripe ? 'pointer-events-none' : ''"
                @click="cancelSubscriptionHandler"
            )
                | {{ cancelSubscriptionBtnText }}
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import SubscriptionOptions from '@/components/subscription/SubscriptionOptions.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useSubscriptionOptions } from '@/composables/useSubscriptionOptions'
import StripeCheckoutWrapper from '@/components/stripe/StripeCheckoutWrapper.vue'
import camelcaseKeys from 'camelcase-keys'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'
import { tenantService } from '../services/tenant.service'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'ChangePlan',
    components: { StripeCheckoutWrapper, SubscriptionOptions },
    setup() {
        const signupSessionStore = useSignupSessionStore()
        const { signupSession } = storeToRefs(signupSessionStore)
        const { tenantId } = useGetters(['tenantId'])
        const { getSignupSession, setModalOpen } = useActions(['getSignupSession', 'setModalOpen'])

        const cancelSubscriptionLoading = ref(false)
        const subscription = ref({})
        const showMessage = ref(false)
        const finishUpgradeMessage = ref('Your subscription has changed successfully! The price will change at the end of the billing month')
        const { previousLineItems, submitToStripe, changePlanBtnText, form, isValid, creatingAccount, formSubmitHandler } = useSubscriptionOptions()
        const previousLineItemsLoaded = ref(false)
        const cancelSubscriptionBtnText = computed(() => cancelSubscriptionLoading.value ? 'Please wait...' : 'Cancel Subscription')

        const getSubscription = async () => {
            const response = await tenantService.getSubscription(tenantId.value)
            subscription.value = {...camelcaseKeys(response || {})}
        }

        const finishUpgrade = async () => {
            // now we load the signupsession again
            await getSignupSession()

            showMessage.value = true
            creatingAccount.value = false
            submitToStripe.value = false
        }

        const cancelSubscriptionHandler = () => {
            setModalOpen({
                isVisible: true,
                data: {
                    subscriptionId: subscription.value?.subscriptionSourceId,
                    keepOpen: true,
                    modalClass: ''
                },
                componentName: 'ModalCancelSubscription'
            })
        }

        watch(previousLineItems, (newPreviousLineItems) => {
            if (newPreviousLineItems?.length) previousLineItemsLoaded.value = true
        }, { immediate: true })

        watch([tenantId, signupSession], async ([newTenantId, newSignupSession]) => {
            await getSubscription()
            
            previousLineItems.value = [...(newSignupSession?.content?.lineItems || [])]
        }, { immediate: true })

        return {
            previousLineItems,
            signupSession,
            submitToStripe,
            changePlanBtnText,
            form,
            isValid,
            subscription,
            creatingAccount,
            previousLineItemsLoaded,
            finishUpgradeMessage,
            showMessage,
            cancelSubscriptionBtnText,
            finishUpgrade,
            formSubmitHandler,
            cancelSubscriptionHandler
        }
    }
}
</script>
