<template lang="pug">
    div
        StripeCheckout(
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :session-id="sessionId"
            @loading="v => loading = v"
        )
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { STRIPE_PUBLISHABLE_KEY } from '@/common/constants'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SignupSessionService } from '@/services/signup-session.service'
import { StripeApiService } from '@/services/stripe-api.service'
import { SubscriptionType } from '@/common/enums/subscription.type'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'
import { useAddHardwareStore } from '@/stores/useAddHardwareStore'
import { useSubscriptionOptions } from '@/composables/useSubscriptionOptions'

const { useGetters, useActions } = createNamespacedHelpers('')
const signupSessionService = new SignupSessionService()
const stripeApiService = new StripeApiService()

export default {
    name: 'StripeAddHardwareCheckoutWrapper',
    components: { StripeCheckout },
    props: {
        submitSubscription: {
            type: Boolean,
            default: false
        },
        signupSessionId: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const rawSuccessUrl = `${location.origin}/hardware?success=true`
        const rawCancelUrl = `${location.origin}/hardware?success=false`
        const publishableKey = ref(STRIPE_PUBLISHABLE_KEY)
        const checkoutRef = ref({})
        const sessionId = ref('')
        const loading = ref(false)
        const successUrl = ref(rawSuccessUrl)
        const cancelUrl = ref(rawCancelUrl)
        const signupSessionStore = useSignupSessionStore()
        const addHardwareStore = useAddHardwareStore()
        const { computedHardware: hardware } = storeToRefs(addHardwareStore)
        const { updateAddHardwareAlertMessage, updateShowAddHardwareAlert, updateAddHardwareAlertType, updateSubmitHardwareToStripe } = addHardwareStore
        const { computedSignupForm } = storeToRefs(signupSessionStore)
        const email = computed(() => computedSignupForm.value?.email)
        const submitSubscription = computed(() => props.submitSubscription)
        const signupSessionId = computed(() => props.signupSessionId)

        const localLineItems = ref( [{
            price: '',
            quantity: 1
        }])

        const submit = async () => {
            updateAddHardwareAlertMessage('')
            updateShowAddHardwareAlert(false)
            //firstly we go and create the save of the state
            try {
                let newLineItems = JSON.parse(JSON.stringify(localLineItems.value))
                console.log('-------- about to add hardware')
                await signupSessionService.addHardware(signupSessionId.value, newLineItems)

                // if (!lineItems.value?.at(0).priceId) return
                // now we create the session
                const session = await stripeApiService.createSession(email.value, rawSuccessUrl, rawCancelUrl, newLineItems, false, 'payment')
                sessionId.value = session.id
                checkoutRef.value.redirectToCheckout()
                updateAddHardwareAlertMessage('Redirecting to payment page, please wait...')
                updateAddHardwareAlertType('success')
            } catch (e) {
                console.log('-------- THERE WAS AN ERROR')
                console.log('error occurred, show the use the userthe error too', e)
                updateAddHardwareAlertMessage('Could not complete the transaction, please check with your system admin')
                updateAddHardwareAlertType('error')
            } finally {
                updateSubmitHardwareToStripe(false)
                updateShowAddHardwareAlert(true)
            }
        }

        watch(submitSubscription, (newValue) => {
            if (newValue) submit()
        })

        watch(hardware, (newHardware) => {
            // if we have some kind of hardware option, we add it to the list if none already exists, otherwise we change it
            localLineItems.value = [...newHardware]
        }, { immediate: true })

        return {
            sessionId,
            publishableKey,
            checkoutRef,
            loading,
            successUrl,
            cancelUrl,
            localLineItems
        }
    }
}
</script>
