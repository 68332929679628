<template lang="pug">
    .container.m-auto.mt-6
        h1(class="mt-2 mb-8 text-3xl text-center") Billing History
        DataTable(
            :headers="headers"
            :items="invoices"
            @download-url="downloadUrlHandler"
        )
</template>

<script>
import DataTable from "@/components/shared/DataTable"
import { billingHistoryService } from '@/services/billing-history.service'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { onMounted, ref } from '@vue/composition-api'
import { format, parseISO } from 'date-fns'
import { downloadFileHandler } from '@/modules/fileHandlers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: "BillingHistory",
    components: { DataTable },
    setup() {
        const { tenantId } = useGetters(['tenantId'])
        const invoices = ref([])
        const headers = ref([
            { text: 'Date Issued', value: 'date', class: "w-150", sortable: false, },
            {
                text: 'Invoice No',
                align: 'start',
                sortable: false,
                value: 'invoiceNumber', class: "w-200"
            },
            { text: 'Description', value: 'description', sortable: false, },
            { text: 'Download Invoice', value: 'downloadLink', sortable: false, },
        ])

        const getBillingHistory = async () => {
            const history = await billingHistoryService.getBillingHistory(tenantId.value)
            invoices.value = [...history.map((inv) => ({
                ...inv,
                date: format(parseISO(inv.date), 'dd/MM/yyyy'),
                description: inv.lines?.data?.reduce((curr, l) => `${curr},${l.description}`, '').slice(1)
            }))]
        }

        const downloadUrlHandler = (url) => {
            downloadFileHandler(url)
        }

        onMounted(async () => {
            await getBillingHistory()
        })

        return { invoices, headers, downloadUrlHandler }
    }
};
</script>
