import { NODE_API_HTTP } from '@/common/constants'
import { axiosInstance } from '@/utils/axios'
import camelcaseKeys from 'camelcase-keys'

export default class BillingHistoryService {
    constructor() {
        this.nodeApi = `${NODE_API_HTTP}/api/v1/billing-history`
    }

    async getBillingHistory(tenantId) {
        const url = `${this.nodeApi}/${tenantId}`
        const { data: history } = await axiosInstance.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return camelcaseKeys(history, {deep: true})
    }
}

export const billingHistoryService = new BillingHistoryService()
