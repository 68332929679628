<template lang="pug">
    .container.m-auto.mt-6(v-if="showSuccessMessage")
        | {{ successMessage }}
        .text-center
            v-btn(
                type="button"
                color="success"
                depressed
                class="w-200 mt-8 mb-6"
                @click="backToDashboardHandler"
            )
                | Back to Dashboard
    div(class="v-form py-8 px-4 max-w-840 lg:w-4/5 xl:w-3/5 m-auto" v-else ref="form" v-model="isValid")
        h1(class="mt-2 mb-8 text-3xl text-center")
            | Purchase Hardware

        div
            | All hardware packs are supplied subject to acceptance of the HealthConex
            a(:href="hardwareAgreementLink" target="_blank" class="mx-1") Hardware Agreement.
            | Need help choosing? 
            a(href="https://www.ultimateq.health/hardware-options" target="_blank") Click here.


        HardwareOptions(:showNoHardware="false" :addMore="true")
        div(v-if="hardware.length" class="col-span-2 h-0")
            StripeAddHardwareCheckoutWrapper(
                :submit-subscription="computedSubmitHardwareToStripe"
                :signup-session-id="signupSessionId"
            )
        v-alert.mt-8.flex.justify-center.flex-col(
            v-if="computedShowAddHardwareAlert"
            :type="computedAddHardwareAlertType"
            class="text-sm mx-5 mt-8 mb-0"
        ) {{ computedAddHardwareAlertMessage }}


        div(class="col-span-2 mb-5 total-cost")
            div(
                    class="total-cost-line flex items-center justify-between p-4 border-b-2"
                )
                    div Floorstand Kiosk (QTY: 2)
                    div $2,990
            div(
                    class="total-cost-line flex items-center justify-between p-4 border-b-2"
                )
                    div Freestanding Desk Kiosk (QTY: 1)
                    div $1,495
            div(class="total-cost-line  flex items-center justify-between p-4 border-b-2")
                h2 Total (includes GST)
                h2 $4,485

        .text-center
            v-btn(
                type="button"
                color="success"
                depressed
                class="w-200 mt-8 mb-6"
                :disabled="!hardware.length"
                :class="computedSubmitHardwareToStripe || !hardware.length ? 'pointer-events-none' : ''"
                @click="formSubmitHandler"
            )
                fa-icon(v-if="computedSubmitHardwareToStripe" icon="spinner" spin class="mr-2")
                | {{ continueBtnText }}
</template>

<script>
import StripeCheckoutWrapper from '@/components/stripe/StripeCheckoutWrapper.vue'
import HardwareOptions from '@/components/subscription/HardwareOptions.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useSubscriptionOptions } from '@/composables/useSubscriptionOptions'
import StripeAddHardwareCheckoutWrapper from '@/components/stripe/StripeAddHardwareCheckoutWrapper.vue'
import { useAddHardwareStore } from '@/stores/useAddHardwareStore'
import { storeToRefs } from 'pinia'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { SignupSessionService } from '@/services/signup-session.service'
import { HARDWARE_AGREEMENT_LINK } from '@/common/constants'

const { useGetters } = createNamespacedHelpers('')
const signupSessionService = new SignupSessionService()

export default {
    name: 'ChangeHardware',
    components: { StripeAddHardwareCheckoutWrapper, StripeCheckoutWrapper, HardwareOptions },
    setup(_, { root }) {
        const route = root.$route
        const router = root.$router
        const query = route.query
        const success = computed(() => query.success)
        const hardwareAgreementLink = ref(HARDWARE_AGREEMENT_LINK)
        const successMessage = ref('')
        const showSuccessMessage = ref(false)
        const addHardwareStore = useAddHardwareStore()
        const signupSessionStore = useSignupSessionStore()
        const { computedHardware: hardware, computedAddHardwareAlertMessage, computedAddHardwareAlertType, computedShowAddHardwareAlert, computedSubmitHardwareToStripe } = storeToRefs(addHardwareStore)
        const { updateHardware, updateSubmitHardwareToStripe } = addHardwareStore
        const { signupSessionId } = storeToRefs(signupSessionStore)
        const { form, isValid } = useSubscriptionOptions()

        const validate = () => {
            isValid.value = form.value.validate()
            return isValid.value
        }

        const formSubmitHandler = () => {
            validate()
            if (!isValid.value) {
                return
            }
            updateSubmitHardwareToStripe(true)
        }

        const continueBtnText = computed(() =>
            computedSubmitHardwareToStripe.value ? 'Please wait...' : 'Continue'
        )

        const removeAddedHardware = async () => {
            // first we reload what the user had originally pushed and then remove it from the database
            // there is a race condition if the user closes the tab before we can do anything
            //TODO: move this to the API so we only update it when the user has paid
            const lastAddedHardware = await signupSessionService.getLastAddedHardware(signupSessionId.value)
            updateHardware([...lastAddedHardware])
            const session = await signupSessionService.removeLastAddedHardware(signupSessionId.value)
        }

        const backToDashboardHandler = () => {
            router.push({ name: 'Dashboard' })
        }

        watch([success, signupSessionId], async ([newSuccessValue, newSignupSessionId]) => {
            console.log('success value and signup session', newSuccessValue, newSignupSessionId)
            if (!newSignupSessionId) return
            // because the success value from the query params can be true/false/undefined
            // we only want to run this function if it has been 'false' and then redirect to the same page
            // after we have run so we don't delete anything else
            if (newSuccessValue === 'false') {
                await removeAddedHardware()
                // now we refresh the page, by going 2 back in history (before the current page and stripe checkout)
                await router.push({ name: 'ChangeHardware' })
            } else if (newSuccessValue === 'true') {
                showSuccessMessage.value = true
                successMessage.value = 'Success! You will receive a notification about your new hardware'
            }
        })

        return {
            isValid,
            form,
            continueBtnText,
            computedSubmitHardwareToStripe,
            signupSessionId,
            computedAddHardwareAlertMessage,
            computedShowAddHardwareAlert,
            computedAddHardwareAlertType,
            hardware,
            showSuccessMessage,
            successMessage,
            hardwareAgreementLink,
            backToDashboardHandler,
            formSubmitHandler
        }
    }
}
</script>
